import React, { FunctionComponent, useEffect, useState, useCallback, Fragment } from 'react';
import axios from 'axios';
import './App.scss';
import './cfr.scss';
import FormWrapper from './components/FormWrapper';
import FormMap from './interfaces/FormMap';
import FormRenderer from './components/FormRenderer';
import { FormContext, FormContextData } from './context/FormContext';
import Step from './components/Step/Step';
import { generateKeyPair } from 'crypto';
import { usePlaidLink } from 'react-plaid-link';
import FormSection from './interfaces/FormSection';
import ProgressTrack from './components/ProgressTrack';
import CompFormSection from './components/FormSection';
import SendSummary from './components/FormItem/SendSummary';
import UpdateForm from './interfaces/UpdateForm';
import KeyValue from './interfaces/KeyValue';
import useEvent from './useEvent';
import Modal from './components/Modal';
import Loader from './components/Loader';
import VisibilityService from './service/VisibilityService';
import ChurchinvestorsFundTheme from './components/Theme/ChurchInvestorsFund/ChurchInvestorsFundTheme';
import Theme from './components/Theme';


const App:FunctionComponent = () => {
  
  let [formMap, setFormMap] = useState<FormMap>();
  
  //const [offset, setOffset] = useState(0);

  let [formData, setFormData] = useState<Map<string, string>>(getDefaultStateMap('formData'));
  let [currentStep, setCurrentStep] = useState<number>(getDefaultStateNumber('currentStep'));
  let [formRevision, setFormRevision] = useState<number>(0);
  let [formErrors, setFormErrors] = useState<Map<string, boolean>>(getDefaultStateMap('formErrors'));
  let [finished, setFinished] = useState<string>('');
  let [formKey, setFormKey] = useState<string>(getDefaultStateString('formKey'));
  let [hash, setHash] = useState<string>('');
  let [modal, setModal] = useState<string>('');
  let [status, setStatus] = useState<string>('');

  //called when compnent mounts
  useEffect(() => {
    
    const formKeyLocal = getDefaultStateString('formKey');
    if(window.location.pathname !== '/' && (!formKey || !formKeyLocal)) {
      resetApp();
    }
    if(formKeyLocal) {
      setFormKey(formKeyLocal);
    }
  }, []);

  // function getDefaultFormKey():string {
  //   return JSON.parse(localStorage.getItem('formKey') || '');
  // }
  function getDefaultStateString(key:string):string {
    let string = sessionStorage.getItem(key)
    return string ? JSON.parse(string) : '';
  }

  function getDefaultStateNumber(key:string):number {
    return Number(JSON.parse(sessionStorage.getItem(key) || '0'));
  }

  function getDefaultStateMap(key:string):Map<string, any> {
    let rawMap = sessionStorage.getItem(key);
    if(rawMap) {
      return new Map(JSON.parse(rawMap));
    } else {
      return new Map();
    }
    
  }

  function updateFormData(key:string, value:string) {
    formData.set(key, value);
    setFormData(formData);
    sessionStorage.setItem("formData", JSON.stringify(Array.from(formData.entries())));
    updateForm();
  }

  function updateFormError(key:string, value:boolean) {
    let errors = formErrors;
    errors.set(key, value);
    setFormErrors(errors);
    sessionStorage.setItem("formErrors", JSON.stringify(Array.from(errors.entries())));
    updateForm();
  }

  function removeFormData(key:string) {

    let entries = formData.keys();
    let updatedFormData = formData;
    Array.from(entries).forEach((value:string) => {
      if(value.startsWith(key)) {
        updatedFormData.delete(value);
      }
    });
    //console.log(formData);
    setFormData(updatedFormData);
    sessionStorage.setItem("formData", JSON.stringify(Array.from(formData.entries())));
    updateForm();
  }


  function updateForm() {
    let newFormRevision = formRevision + 1;
    sessionStorage.setItem("formRevision", JSON.stringify(newFormRevision));
    setFormRevision(newFormRevision);
  }

  function goToStep(step:number) {
    setCurrentStep(step);
    sessionStorage.setItem("currentStep", JSON.stringify(step));
    window.scrollTo({
      top: 0
    });
  }

  function nextStep() {
    let step = currentStep + 1;
    setCurrentStep(step);
    sessionStorage.setItem("currentStep", JSON.stringify(step));
    saveForm();
    window.scrollTo({
      top: 0
    });
  }
  function prevStep() {
    let step = currentStep - 1;
    sessionStorage.setItem("currentStep", JSON.stringify(step));
    setCurrentStep(currentStep - 1);
    window.scrollTo({
      top: 0
    });
  }



  function saveForm() {

    let saveForm: UpdateForm = {} as UpdateForm;
    saveForm.formStatus = "update";
    saveForm.formkey = formKey;

    let values: KeyValue[] = [];
    formData.forEach((value: string, key: string) =>
      values.push({ key: key, value: value })
    );
    saveForm.formData = values;

    axios({
      "method": "POST",
      "url": `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/updateForm`,
      "data": saveForm
    })
      .then((response) => {
        console.log(response.data);


      })
      .catch((error) => {
        console.log(error)
      })
  }



  function finishForm() {
    setStatus('loading');
    window.scrollTo({
      top: 0
    });
    let saveForm: UpdateForm = {} as UpdateForm;
    saveForm.formStatus = "finish";
    saveForm.formkey = formKey;
    saveForm.formType = finished;
    let values: KeyValue[] = [];
    formData.forEach((value: string, key: string) =>
      values.push({ key: key, value: value })
    );
    saveForm.formData = values;
    let finalPage: FormSection = formMap?.finalPages.filter((formSection: FormSection) => formSection.finalPage === finished)[0] || {} as FormSection;
    axios({
      "method": "POST",
      "url": `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/finishForm`,
      "data": saveForm
    })
      .then((response) => {
        //console.log(response.data);
        setStatus('finished');

        window.history.pushState({ currentStep: currentStep }, finalPage.name, finalPage.alias);
      })
      .catch((error) => {
        console.log(error)
        setStatus('finished');
        window.history.pushState({ currentStep: currentStep }, finalPage.name, finalPage.alias);
      })
  }

  //load the form json  
  const fetchData = React.useCallback(() => {
    
    axios({
      "method": "GET",
      "url" : `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getForm/${process.env.REACT_APP_FORM_SPEC}`
    })
    .then((response) => {
      console.log(response.data);
      let formMap:FormMap = response.data;
      setFormMap(formMap);
      if(formMap.systemValues) {
        addSystemValues(formMap.systemValues);
      }
      
    })
    .catch((error) => {
      console.log(error)
    })
    if(!formKey) {
      axios({
        "method": "GET",
        "url" : `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/init/${process.env.REACT_APP_FORM_SPEC}`
      })
      .then((response) => {
        console.log(response.data);
        setFormKey(response.data);
        sessionStorage.setItem("formKey", JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }, [])

  

  
  React.useEffect(() => {
    if(finished) {
      finishForm();
    }
  }, [finished])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  React.useEffect(() => {
    if(hash && hash.startsWith('modal')) {
      setModal(hash);
    } else {
      setModal('');
    }
  }, [hash])


  React.useEffect(() => {
    if (modal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
  }, [modal])


  React.useEffect(() => {
    let currentSection:FormSection | undefined = getCurrentSection();
    if (currentSection) {
      
      if(!window.history.state || window.history.state.currentStep !== currentStep) {
        window.history.pushState({currentStep: currentStep}, currentSection.name, (currentStep == 0 ? '/' : currentSection.alias));
        
      }
      
    }
  }, [currentStep])

  useEvent('hashchange', onHashChange, false);
  useEvent('popstate', onPopstate, false);

  function onHashChange(e:HashChangeEvent) {
    let hash = e.newURL.split('#')[1];
    if (hash) {
      setHash(hash);
    } else {
      setHash('');
    }
  }

  function onPopstate(e:PopStateEvent) {
    if (status !== 'finished' && e.state && e.state.currentStep) {
      let currentStep = e.state.currentStep;
      console.log(e.state.currentStep);
      setCurrentStep(currentStep);
    } else {
      setCurrentStep(0);
    }
    if (status === 'finished') {
      resetApp();
    }
  }



  function resetApp() {
    sessionStorage.clear();
    setCurrentStep(0);
    setFormRevision(0);
    setFormData(new Map());
    setFormErrors(new Map());
    setFormKey('');
    setHash('');
    setModal('');
    setStatus('');
    setFinished('');
    window.location.href='/';
  }

  function closeModal() {
    window.history.replaceState({}, document.title, ".");
    setHash('');
    setModal('');

  }

  function addSystemValues(systemValuesString:string) {
    if (systemValuesString) {
      let systemValues:string[] | undefined = systemValuesString?.split(',');
      systemValues?.forEach((value:string) => {
      let systemValue:string[] | undefined = value?.split('|');
      if(systemValue) {
        
          updateFormData("system." + systemValue[0] ,systemValue[1]);
        
      }
    })
      
      
    }
    
  }


  function getKey(formAlias:string, sectionAlias:string) {
    return formAlias + '.' + sectionAlias;
  }

  function showIf(formItem:any):boolean {
    let localContext:FormContextData = {formAlias: formMap?.alias || '', formData : formData, updateFormData : updateFormData, nextStep : nextStep, prevStep : prevStep, formRevision : formRevision, removeFormData : removeFormData, updateForm : updateForm, setFinished : setFinished }
    return VisibilityService.showIf(formItem, localContext);
   }

  function getCurrentSection():FormSection | undefined {
    return formMap?.sections.filter((formSection:FormSection) => showIf(formSection))[currentStep];
  }




  const cfr = false; 



  return (
    <Theme theme={process.env.REACT_APP_TEMPLATE || ''} resetApp={resetApp}>
      <main>
        <div className="form-wrapper">
          {formMap &&
          <FormContext.Provider value={{formAlias: formMap.alias, formData : formData, updateFormData : updateFormData, nextStep : nextStep, prevStep : prevStep, formRevision : formRevision, removeFormData : removeFormData, updateForm : updateForm, setFinished : setFinished }} >
            <div>
              <div>
                <ProgressTrack finished={finished ? true : false} formErrors={formErrors} goToStep={goToStep} sections={formMap.sections?.filter((formSection:FormSection) => showIf(formSection))} currentStep={currentStep} />
              </div>
              <div className="step-wrapper">
            {status !== 'finished' && formMap.sections?.filter((formSection:FormSection) => showIf(formSection)).map((formSection:FormSection, index:number) => 
              <div key={getKey(formMap!.alias, formSection.alias) + '-' + index} className={"currentstep" + currentStep + "-" + index} style={{display: (index === currentStep ? 'block' : 'none')}}>
              <Step sectionActive={index === currentStep} currentStep={currentStep} maxStep={formMap?.sections.filter((formSection:FormSection) => showIf(formSection)).length || 0} updateFormError={updateFormError}  parentAlias={formMap!.alias} formSection={formSection} />
              </div>
            )}
            {status === 'finished' && 
              <div>
                <CompFormSection formDirty={false} sectionActive={true} formSection={formMap.finalPages.filter((formSection:FormSection) => formSection.finalPage === finished)[0]} formValidator={(value) => true} parentAlias={'none'} isFormValid={true}  />
              </div>
            }
            </div>
            </div>
          </FormContext.Provider>
          }
        </div>
        </main>
      
        {modal && <Modal alias={modal} modalCloseHandler={closeModal} />}
        {status === 'loading' && <Loader />}
        </Theme>
  );
}

export default App;